import React, { useEffect, useState } from "react";
import axios from "axios";

const Disclaimer = ({ onDisclaimerSuccess,usernm }) => {
  const [username, setUsername] = useState("");

  // Fetch username from session when the component mounts
  useEffect(() => {
    setUsername(usernm);
  }, [usernm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onDisclaimerSuccess(username); // Call this to navigate to ExcelTable
  };

  return (
    <div style={styles.container}>
      <h2>Disclaimer</h2>
      
      <p>
        This is a tool, made for a close group for study purposes only.
      </p>
      <p>
        We are not doing any kind of recommendation for any purpose.
      </p>
      <p>
        We are not using any kind of live data; all rates are dummy and based
        on this, one should not trade in the live market.
      </p>
      <p>
        I understand completely the risks associated with live trading and am
        doing it at my own risk. I will not have a complaint with anyone.
      </p>

      <form onSubmit={handleSubmit}>
        <button type="submit" style={styles.button}>
          Ok
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50vh",
    maxWidth: "400px",
    margin: "100px auto",
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  p: {
    padding: "10px",
    fontSize: "16px",
  },
  button: {
    padding: "10px 15px",
    fontSize: "18px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default Disclaimer;
