import React, { useState } from "react";
import axios from "axios"; // Import axios

const Register = ({ onRegisterSuccess }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    // Full Name: Only letters and exactly two words (first name and last name)
    // const fullNameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)$/;
    // if (!fullNameRegex.test(formData.fullName)) {
    //   setError("Full Name must contain both first and last name (letters and space only).");
    //   return false;
    // }

    // Mobile Number: Must be a valid number, length check
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile)) {
      setError("Mobile number must be 10 digits.");
      return false;
    }

    // Email Validation: Check if the email format is correct
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email address.");
      return false;
    }

    // Password Validation: Check for matching passwords
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      return false;
    }

    // Clear error message if validation passes
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before sending request
    if (!validateForm()) return;

    setLoading(true); // Start loading

    try {
      const response = await axios.post('/api/register', {
        username: formData.fullName, // Assuming 'username' is the full name field in the API
        mobile: formData.mobile,
        email: formData.email,
        password: formData.password,
        registrationDate: new Date(), // Add PaymentDate field if it exists
        deviceId: { type: String, default: '0' },
      });

      setLoading(false); // Stop loading
      setError("");
      setSuccess(`Registration successful! Your User ID is ${response.data.id}`); // Display the ID

      // Call the onRegisterSuccess prop to navigate to the login form
      onRegisterSuccess();
    } catch (err) {
      setLoading(false); // Stop loading
      setError(err.response?.data?.message || "Registration failed");
      setSuccess("");
    }
  };

  return (
    <div style={styles.container}>
      <h2>Registration Form</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label>Name & Surname</label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            style={styles.input}
            aria-label="Full Name"
          />
        </div>

        <div style={styles.inputGroup}>
          <label>Mobile Number:</label>
          <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
            style={styles.input}
            aria-label="Mobile Number"
            pattern="[0-9]{10}" // Optional: to ensure 10 digits (HTML5 validation)
            maxLength="10" // Optional: ensures no more than 10 characters are typed
          />
        </div>

        <div style={styles.inputGroup}>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={styles.input}
            aria-label="Email"
          />
        </div>

        <div style={styles.inputGroup}>
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            style={styles.input}
            aria-label="Password"
          />
        </div>

        <div style={styles.inputGroup}>
          <label>Confirm Password:</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            style={styles.input}
            aria-label="Confirm Password"
          />
        </div>
        

        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? "Submitting..." : "Register"}
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "60vh",
    maxWidth: "400px",
    margin: "100px auto", // Added top and bottom margins
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px 15px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default Register;
