import React, { useState, useEffect } from "react";
import axios from "axios";
import { io } from "socket.io-client"; // Import WebSocket client
import DeviceDetector from "device-detector-js";

const Login = ({ onLoginSuccess, toggleForm, toggleForgotPassword }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    deviceId: "",
  });

  const [error, setError] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  //const [socket, setSocket] = useState(null); // WebSocket connection

  
    // Establish WebSocket connection on component mount
   // const socket = io('https://www.sharesoft.co.in:10000'); // Connect to the WebSocket server


  const [deviceName, setDeviceName] = useState("");

  useEffect(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    const deviceInfo = `${device.device?.brand || "Unknown"} ${device.device?.model || "Device"}`;
    setDeviceName(deviceInfo);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  // const calculateExpiryDetails = (registrationDate) => {
  //   try {
  //     console.log(registrationDate)
  //     const paymentDateObj = new Date(registrationDate);
  //     if (isNaN(paymentDateObj)) throw new Error("Invalid payment date");

  //     const expiryDateObj = new Date(paymentDateObj);
  //     expiryDateObj.setDate(expiryDateObj.getDate() + 30); // Add 30 days
  //     console.log(expiryDateObj)
  //     const today = new Date();
  //     const timeDiff = expiryDateObj - today; // Difference in milliseconds
  //     const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
     
  //     return {
  //       expiryDate: expiryDateObj.toISOString().split("T")[0],
  //       daysLeft: daysLeft > 0 ? daysLeft : 0,
  //     };
  //   } catch (error) {
  //     console.error("Error calculating expiry details:", error);
  //     return { expiryDate: "N/A", daysLeft: 0 };
  //   }
  // };
 

  const calculateExpiryDetails = (paymentDate,registrationDate) => {
    try {
      console.log(registrationDate)
      const regDateObj=new Date(registrationDate)
      //const paymentDateObj = new Date(paymentDate);
      if (isNaN(regDateObj)) throw new Error("Invalid payment date");
      const today = new Date();
      let daysLeft
      let formattedExpiryDate
   if (paymentDate==null)
    {    
    const expiryDateObj = new Date(regDateObj);
    expiryDateObj.setDate(expiryDateObj.getDate() + 7); // Add 30 days
    console.log(expiryDateObj)
   // const today = new Date();
    const timeDiff = expiryDateObj - today; // Difference in milliseconds
     daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
     formattedExpiryDate = expiryDateObj.toLocaleDateString("en-GB").replace(/\//g, "-");  
   }else {
    const payDateObj=new Date(paymentDate)
    const expiryDateObj = new Date(payDateObj);
    expiryDateObj.setDate(expiryDateObj.getDate() + 30); // Add 30 days
    console.log(expiryDateObj)
   
    const timeDiff = expiryDateObj - today; // Difference in milliseconds
     daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
     formattedExpiryDate = expiryDateObj.toLocaleDateString("en-GB").replace(/\//g, "-");
  
   }
     
      return {
        expiryDate: formattedExpiryDate,
        daysLeft: daysLeft > 0 ? daysLeft : 0,
      };
    } catch (error) {
      console.error("Error calculating expiry details:", error);
      return { expiryDate: "N/A", daysLeft: 0 };
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const deviceId = ipResponse.data.ip;
console.log(deviceId)
      const payload = { ...credentials, deviceId }; // Include deviceId in the payload
      const response = await axios.post("/api/login", payload);
      const { userId,username, paymentDate,email,isPaymentReceived,registrationDate } = response.data;
     
      
      console.log(`Username: ${username}`);
      console.log(`Registration Date: ${registrationDate}`);
      console.log(`Payment Date: ${paymentDate}`);
      console.log(`Saved Device ID: ${deviceId}`);
      setPaymentDate(paymentDate);
      // Register the user with the WebSocket server
      //socket.emit("register", username); // Send username to WebSocket server for tracking

      const { expiryDate, daysLeft } = calculateExpiryDetails(paymentDate,registrationDate);
      console.log(`Days left: ${daysLeft}`);

      if (daysLeft < 1) {
        setError("Your subscription is complete. Please renew to continue.");
      } else {
      
      onLoginSuccess(username, paymentDate,email,deviceId,userId,registrationDate); // Successful login
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Server Error";
      setError(errorMessage);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Login Form</h2>

      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>
          Login
        </button>
      </form>
      <p>
        New user?{" "}
        <button
          onClick={toggleForm}
          style={{
            color: "#4CAF50",
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
        >
          Register here
        </button>
      </p>
      <p>
        Forgot your password?{" "}
        <button
          onClick={toggleForgotPassword}
          style={{
            color: "#4CAF50",
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
        >
          Click here
        </button>
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50vh",
    maxWidth: "400px",
    margin: "100px auto", // Added top and bottom margins
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px 15px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default Login;
