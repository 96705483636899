// ****************** was working with micro */
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios'; 
const ExcelTable = ({ usernm,payDate,emailId,dvId ,uId,regDate}) => {
  const [excelData, setExcelData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // State for current page
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString()); 
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [paymentDate, setpaymentDate] = useState(""); // State to store the username after login
  const [registrationDate, setregistrationDate] = useState(""); 
  const [email, setDeviceEmail] = useState(""); // State to store the username after login
  const [deviceId, setDevicedeId] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    setUserId(uId);
    setUsername(usernm);
    setpaymentDate(payDate);
    setregistrationDate(regDate);
    setDeviceEmail(emailId);
//    setDevicedeId(deviceId);
  }, [usernm]);
  const rowsPerPage = 20; // Number of rows to display per page
  const headers = [
    'SIGNAL', 'SYMBOL',  'LTP', '% CHANGE', 
    'ENTRY PRICE', 'STOP LOSS', 'TARGET-1', 'TARGET-2', 
   'TURNOVER', 'OPEN INTEREST', 'STOCK RATE', 'TRAILING STOP LOSS' ,
    'VOLUME','P&L','REMARK','POSITION'
  ];
  const fetchExcelData = async (e) => {
    const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const deviceId = ipResponse.data.ip;
   const email=emailId;//'mrunal.kulkarni79@gmail.com'
   axios.post('/api/read-excel', { email ,deviceId}, { responseType: 'arraybuffer' })
      .then((response) => {
        const data = response.data;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[1];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 8 });
        const limitedData = jsonData.slice(0, 20);
  
        // Insert new columns at different positions
        const updatedData = limitedData.map((row) => {
          row.splice(1, 0, '');  // New column at index 1
          row.splice(9, 0, '');  // New column at index 9
          row.splice(10, 0, ''); // New column at index 10
          row.splice(11, 0, ''); // New column at index 11
          row.splice(12, 0, ''); // New column at index 12
          row.splice(23, 0, ''); // New column at index 23
          row.splice(24, 0, ''); // New column at index 24
          row.splice(25, 0, ''); // New column at index 25
          row.splice(26, 0, ''); // New column at index 26
          row.splice(27, 0, ''); // New column at index 27
     //     row.splice(28, 0, ''); // New column at index 28
          return row;
        });
  
        // Calculate values for column index 21 and 22
        const withCalculatedColumns = updatedData.map((row) => {
          const col16Value = parseFloat(row[16]) || 0;
          const col20Value = parseFloat(row[20]) || 1;
          const col6Value = parseFloat(row[6]) || 0;
          const col9Value = parseFloat(row[9]) || 0;
          const col14Value = parseFloat(row[14]) || 0;
          const col15Value = parseFloat(row[15]) || 0;
          const col13Value= parseFloat(row[13]) || 0;
        
          // Calculate column 23
          row[23] = (((col6Value - col15Value) * 100) / (col14Value - col15Value)).toFixed(2);
  
          if (row[23] > 80 && row[23] < 100) {
            row[25] = 'Rally';
          } else if ((row[23] > 66 && row[23] < 80) || (row[23] > 20 && row[23] < 33)) {
            row[25] = 'Hold';
          } else if (row[23] < 20) {
            row[25] = 'Fall';
          } else if (row[23] > 33 && row[23] < 66) {
            row[25] = 'Exit';
          }
  
          if (row[7] > 0 && row[8] > 0) {
            row[26] = 'Fresh Buying';
          } else if (row[7] > 0 && row[8] < 0) {
            row[26] = 'Fresh Short';
          }

          let entval=parseFloat((col13Value + (col13Value * 0.1)))
          if (col6Value > parseFloat((col13Value * 1.1).toFixed(2))) {
          row[27] =  parseFloat(((col6Value-entval)*100)/entval).toFixed(2) 
          }else  if (col6Value < parseFloat((col13Value * 1.1).toFixed(2))) {
            row[27] =  parseFloat(((entval-col6Value)*100)/entval).toFixed(2) 
            }else {
              row[27] =100

            }
         
          return row;
        });
  
        // Update the state with the data containing calculated values
        const sortedData = sortData(withCalculatedColumns, 27); // Sort based on column index 27

        // Update the state with the sorted data
        setExcelData(withCalculatedColumns);
        setData(sortedData);
      })

      .catch((error) => {
       // console.error('Error fetching the Excel file:', error);
      //  const confirmLogout = window.confirm(
      //       "You are log out here"
      //      );
      //  setError(error)
      window.location.href = '/'; // Replace with your actual login route
      });
  };  
  console.log(userId)
  console.log(username)
  console.log(paymentDate)
  const sortData = (dataToSort, columnIndex) => {
    return [...dataToSort].sort((a, b) => {
      const aValue = parseFloat(a[columnIndex]) || 0; // Use 0 if NaN
      const bValue = parseFloat(b[columnIndex]) || 0; // Use 0 if NaN

      return  aValue-bValue ; // Sorting in descending order
    });
  };
  const sortDataN = (dataToSort, columnIndex) => {
    return [...dataToSort].sort((a, b) => {
      const aValue = parseFloat(a[columnIndex]) || 0; // Use 0 if NaN
      const bValue = parseFloat(b[columnIndex]) || 0; // Use 0 if NaN

      return  aValue-bValue ; // Sorting in descending order
    });
  };  
  const roundSecondDecimal = (value) => {
    //debugger
    let firstDigit ;
    let secondDigit;
    const [whole, decimal] = value.toString().split('.');    
   
     firstDigit = parseInt(decimal.charAt(0),10);
     secondDigit = parseInt(decimal.charAt(1),10);   

    // Apply ceil/floor logic to the second digit    
    let adjustedSecondDigit;
    let newDecimal ;
    let newValue ;
   
     
    if (secondDigit > 5)
    {
      adjustedSecondDigit = 0; 
      firstDigit=firstDigit+1;    
      newDecimal = `${firstDigit}${adjustedSecondDigit}`;
      newValue = parseFloat(`${whole}.${newDecimal}`);// If the second digit is 5 or more, set it to 9
    }     
   else if (secondDigit < 5 ){
       adjustedSecondDigit = 0;       
       newDecimal = `${firstDigit}${adjustedSecondDigit}`;
       newValue = parseFloat(`${whole}.${newDecimal}`);// If less than 5, set it to 0
       console.log(newValue)
    }    
    else if (secondDigit = 5)
      {
        adjustedSecondDigit = secondDigit; 
       
        newDecimal = `${firstDigit}${adjustedSecondDigit}`;
        newValue = parseFloat(`${whole}.${newDecimal}`);// If the second digit is 5 or more, set it to 9
      }    
    // Combine back into a number   
    console.log(newValue.toFixed(2))
    return newValue.toFixed(2); // Return as a string with 2 decimal places    
    
  };
  useEffect(() => {
    // Fetch data initially
    fetchExcelData();
    
    // Open WebSocket connection to listen for file changes
    const ws = new WebSocket('wss://www.sharesoft.co.in/api');

    // Listen for messages from the WebSocket server
    ws.onmessage = (message) => {
        if (message.data === 'file-changed') {
            console.log('File changed, fetching updated data...');
            fetchExcelData(); // Refetch the Excel data when the file changes
        }
    };

    const updateTime = () => {
      const now = new Date();
      
      // Format date as dd/mm/yyyy
      const day = String(now.getDate()).padStart(2, '0');  // Add leading zero if needed
      const month = String(now.getMonth() + 1).padStart(2, '0');  // Add leading zero if needed
      const year = now.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;

      // Format time as hh:mm:ss
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const formattedTime = `${hours}:${minutes}:${seconds}`;

      // Set both date and time
      setCurrentTime(`${formattedDate} ${formattedTime}`);
    };

    // Update time initially
    updateTime();

    // Update time every minute (or adjust the interval as needed)
    const intervalId = setInterval(updateTime, 1000);
    // Cleanup WebSocket connection when the component unmounts
    return () => {
        ws.close();
        clearInterval(intervalId);
    };
}, []);


const calculateExpiryDetails = (paymentDate,registrationDate) => {
  try {
    console.log(registrationDate)
    const regDateObj=new Date(registrationDate)
    //const paymentDateObj = new Date(paymentDate);
    if (isNaN(regDateObj)) throw new Error("Invalid payment date");
    const today = new Date();
    let daysLeft
    let formattedExpiryDate
 if (paymentDate==null){
  
  const expiryDateObj = new Date(regDateObj);
  expiryDateObj.setDate(expiryDateObj.getDate() + 3); // Add 30 days
  console.log(expiryDateObj)
 // const today = new Date();
  const timeDiff = expiryDateObj - today; // Difference in milliseconds
   daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
   formattedExpiryDate = expiryDateObj.toLocaleDateString("en-GB").replace(/\//g, "-");

 }else {
  const payDateObj=new Date(paymentDate)
  const expiryDateObj = new Date(payDateObj);
  expiryDateObj.setDate(expiryDateObj.getDate() + 30); // Add 30 days
  console.log(expiryDateObj)
 
  const timeDiff = expiryDateObj - today; // Difference in milliseconds
   daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
   formattedExpiryDate = expiryDateObj.toLocaleDateString("en-GB").replace(/\//g, "-");

 }
   
    return {
      expiryDate: formattedExpiryDate,
      daysLeft: daysLeft > 0 ? daysLeft : 0,
    };
  } catch (error) {
    console.error("Error calculating expiry details:", error);
    return { expiryDate: "N/A", daysLeft: 0 };
  }
};

// Call the function
const { expiryDate, daysLeft } = calculateExpiryDetails(paymentDate,registrationDate);
// Calculate rows to display for current page
const displayedRows = data.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
//const totalPages = Math.ceil(data.length / rowsPerPage);

  // Define the columns to hide (0, 3, 7, 13, 14, 15, 17, 20)
  //const hiddenColumns = [0,3,4,7,13,14,15,16, 17,20,22];
  const hiddenColumns = [0,3,4,5,7,13,14,15,16,20,23,27];
  return (
    <div style={{ margin: '0 25px' }}>
 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
    Date Time: {currentTime}
  </span>
  <span style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
        Ends On : {expiryDate} ({daysLeft} days left)
      </span>
  <span style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'right' }}>
  {userId}. {username}
  </span>
</div>


      {data.length > 0 && (
        <table name='table1' style={{ borderCollapse: 'collapse', width: '100%', fontFamily: 'Calibri, sans-serif'}}>
          <thead>
          <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  style={{
                    border: '1px solid black',
                    padding: '8px',
                    backgroundColor: '#F3C623',
                    textAlign: 'center',
                    width: header === 'SYMBOL' ? '300px' : '100px', // Increase width for 'Symbol' header
                    fontFamily: 'Calibri, sans-serif'
                 //   cursor: 'pointer' // Change cursor for sortable column
                  }}
              //    onClick={() =>  sortData(index)} // Sort on header click if index is 21
                >
                  {header} 
                  
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            
            {data.map((row, rowIndex) => {
              let cell1Style = { border: '1px solid black', padding: '8px' }; // Default cell 1 style
              let cell1Value = row[1]; // Initialize with original value of cellIndex 1
              const col13Value=parseFloat(row[13])
              const col6Value = parseFloat(row[6]); // Value in column 13
              const col9Value = parseFloat((col13Value + (col13Value * 0.1)).toFixed(2)); // Value in column 9
              const col16Value=parseFloat(row[16])
              const col15Value=parseFloat(row[15])
              const col25Value=row[25]
              const col26Value=row[26]
             
            ///  console.log(col13Value)
             // console.log(col9Value)
              // Conditional logic for BUY/SELL in column 1 based on column 9 and column 13
              if (!isNaN(col9Value) && !isNaN(col13Value)) {
                if (col6Value > (parseFloat((col13Value * 1.1).toFixed(2)))) {
                  cell1Value = 'BUY--'; // Set column 1 to 'BUY'
                  cell1Style.backgroundColor = 'lightgreen'; // Change background to green
                } else if (col6Value < (parseFloat((col13Value/1.1)).toFixed(2))) {
                  cell1Value = '--SELL'; // Set column 1 to 'SELL'
                  cell1Style.backgroundColor = 'orange'; // Change background to red
                }else {
                  cell1Value = 'WAIT'; // Set column 1 to 'SELL'
                  cell1Style.backgroundColor = 'Yellow'; // Change background to red
                }
               
              }
            
                return (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                    const isNumeric = !isNaN(cell); // Check if cell content is numeric
                    let cellStyle = {
                      border: '1px solid black',
                      padding: '8px',
                      width: '100px', // Set fixed width here
                      textAlign: isNumeric ? 'right' : 'left', // Align numbers to the right, text to the left
                      fontFamily: 'Calibri, sans-serif' // Set font to Calibri
                    };
                   
                    if(cellIndex==25){
                      const col1Value=row[1]
                    if (col25Value =='Rally'  )  {
                   
                      cellStyle.backgroundColor = '#B6FFA1'; // Change background to green
                    }else   if (col25Value =='Fall'  )  {
                   
                      cellStyle.backgroundColor = 'lightcoral'; // Change background to green
                    }
                     else if (col25Value =='Exit') {
                     
                      cellStyle.backgroundColor = 'yellow'; // Change background to red
                    }    }
                    if(cellIndex==26){
                      if (col26Value =='Fresh Buying' )  {
                     
                        cellStyle.backgroundColor = '#D3EE98'; // Change background to green
                      } else if (col26Value =='Fresh Short') {
                       
                        cellStyle.backgroundColor = 'lightpink'; // Change background to red
                      }    }
             
                    
                    // Column 4 specific background color for 'call' and 'put'
                    if (cellIndex === 4) {
                      const cellValue = cell.toString().toLowerCase();
                      if (cellValue === 'call') {
                        cellStyle.backgroundColor = 'lightblue'; // Light blue for 'call'
                      } else if (cellValue === 'put') {
                        cellStyle.backgroundColor = 'lightpink'; // Light pink for 'put'
                      }
                    }
                    if(cellIndex==5){
                      const col5Value = parseFloat(cell).toFixed(0);
                      cell=col5Value
                   //   cell=roundSecondDecimal(cell)
                     }
                    if(cellIndex==21){
                    const col21Value = parseFloat(cell).toFixed(2);
                    cell=col21Value
                    cell=roundSecondDecimal(cell)
                    }

                    if (cellIndex === 6) {
                      const col6Value = parseFloat(cell).toFixed(2);
                      cell=col6Value
                    }
                    if (cellIndex === 8) {
                      const col8Value = parseFloat(cell).toFixed(2);
                      cell=col8Value
                    }
                    if (cellIndex === 19) {
                      const col19Value = parseFloat(cell).toFixed(2);
                      cell=col19Value
                    }
                    // Column 8 (index 7) for negative/positive values
                    if (cellIndex === 8) {
                      const numericValue = parseFloat(cell);
                      if (!isNaN(numericValue)) {
                        if (numericValue < 0) {
                          cellStyle.backgroundColor = 'lightcoral'; // Light red for values < 0
                        } else if (numericValue > 0) {
                          cellStyle.backgroundColor = 'lightgreen'; // Light green for values > 0
                        }
                      }
                    }
                    if (cellIndex === 25) {
                      const cellValue = cell.toString().toLowerCase();
                      if (cellValue === 'Rally') {
                        cellStyle.backgroundColor = 'lightgreen'; // Light blue for 'call'
                      } else if (cellValue === 'Exit') {
                        cellStyle.backgroundColor = 'lightyellow'; // Light pink for 'put'
                      }
                    }
                    // Display column 9, 10, 11, 12 with formulas based on column 13
                    let cell1Value = row[1]; // For BUY/SELL logic
                    const col13Value = parseFloat(row[13]);
                    const col6Value = parseFloat(row[6]);
                    const col7Value = parseFloat(row[7]);
                    const col20Value = parseFloat(row[20]);
                    const col9Value = parseFloat((col13Value + (col13Value * 0.1)).toFixed(2));
                  
                    if (!isNaN(col9Value) && !isNaN(col13Value)) {
                      if (col6Value > parseFloat((col13Value * 1.1).toFixed(2))) {
                        cell1Value = 'BUY';
                      } else if (col6Value < parseFloat((col13Value / 1.1).toFixed(2))) {
                        cell1Value = 'SELL';
                      }else{
                        cell1Value = 'WAIT';
                      }
                    }

                    // Retrieve the value of column 1 (index 1) and check if it's 'BUY'
                    const isBuy = cell1Value === 'BUY';
                    const isSell = cell1Value === 'SELL';
                   

                    // Only perform calculations for columns 9, 10, 11, 12 if column 1 is 'BUY'
                    if (isBuy && !isNaN(col13Value) && col13Value!=0 ) {
                     
                     if (cellIndex === 9) {
                        cell = (col13Value * 1.1).toFixed(2) ; 
                        console.log(cell)                       
                        cell=roundSecondDecimal(cell)    
                      } else if (cellIndex === 10) {
                        cell = parseFloat((col13Value)).toFixed(2);
                       cell=roundSecondDecimal(cell)    
                      } else if (cellIndex === 11) {
                        cell = (col13Value *1.5).toFixed(2);
                        cell=roundSecondDecimal(cell)    
                      } else if (cellIndex === 12) {
                        cell = (col13Value  * 2).toFixed(2);
                        cell=roundSecondDecimal(cell);
                      }
                      else if (cellIndex === 24) {
                        cell =  ((col6Value -col9Value)*col20Value).toFixed(2);
                      
                      }
                   
                      
                    }
                    if (isSell && !isNaN(col13Value) ) {
                      let val;
                      if (cellIndex === 9) {
                        cell = (col13Value / 1.1).toFixed(2);
                       cell=roundSecondDecimal(cell);
                      } else if (cellIndex === 10) {
                        cell = (col13Value).toFixed(2);
                        cell=roundSecondDecimal(cell);
                      } else if (cellIndex === 11) {
                        cell = (col13Value /1.5).toFixed(2);
                        cell=roundSecondDecimal(cell);
                      } else if (cellIndex === 12) {
                        cell = (col13Value / 2).toFixed(2); // cell is now a string with 2 decimal places
                      cell = roundSecondDecimal(cell); 
                      } else if (cellIndex === 24) {
                        cell =  ((col9Value -col6Value)*col20Value).toFixed(2);
                      
                      }
                      
                    
                      
                    }
                   
                    // Special handling for cellIndex 1 (BUY/SELL logic)
                    if (cellIndex === 1) {
                      return (
                        <td key={cellIndex} style={cell1Style}>
                          {cell1Value} {/* Display BUY/SELL in column 1 */}
                        </td>
                      );
                    }

                    return !hiddenColumns.includes(cellIndex) && (
                      <td key={cellIndex} style={cellStyle}>
                        {cell}
                      </td>
                    );
                  
                  })}
                   {/* <td style={{ border: '1px solid black', padding: '8px', width: '100px', textAlign: 'center' }}>        
        </td> */}
                </tr>
               
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default ExcelTable;


