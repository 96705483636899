import React, { useState } from "react";
import Register from "./Registration"; // Ensure the path is correct
import Login from "./Login"; // Ensure the path is correct
import ExcelTable from "./ExcelTable"; // Import your ExcelTable component
import Disclaimer from "./Disclaimer";
import ResetPassword from "./ForgotPassword"; // You will create this component for the reset password form

const App = () => {
  const [currentView, setCurrentView] = useState("login"); // Start with the login view
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track if user is authenticated
  const [username, setUsername] = useState(""); // State to store the username after login
  const [paymentDate, setpaymentDate] = useState(""); 
  const [registrationDate, setregistrationDate] = useState(""); 
  const [email, setDeviceEmail] = useState("");
  const [deviceId, setDeviceDeviceId] = useState("");
  const [userId, setUserId] = useState("");
  const handleLoginSuccess = (usernm,payDate,email,deviceId,userId,regDate) => {
    setIsAuthenticated(true); // Set authentication state to true
    setUsername(usernm); // Store the username in state
    setpaymentDate(payDate);
    setregistrationDate(regDate)
    setDeviceEmail(email);
    setDeviceDeviceId(deviceId)
    setUserId(userId);
    setCurrentView("disclaimer"); // Navigate to the disclaimer page
  };

  const handleDisclaimerSuccess = () => {
    setCurrentView("excelTable"); // Navigate to ExcelTable after accepting the disclaimer
  };

  const handleRegisterSuccess = () => {
    setCurrentView("login"); // Navigate to the login form upon successful registration
  };

  const handleForgotPassword = () => {
    setCurrentView("resetPassword"); // Navigate to the reset password form
  };

  const handleResetPasswordSuccess = () => {
    setCurrentView("login"); // After successful password reset, return to the login form
  };

  return (
    <div>
      {currentView === "login" && (
        <Login
     //   deviceId={deviceId} // Pass the deviceId as a prop 
          onLoginSuccess={handleLoginSuccess} 
          toggleForm={() => setCurrentView("register")} // Navigate to registration form
          toggleForgotPassword={handleForgotPassword} // Handle forgot password link click
        />
      )}
      {currentView === "register" && (
        <Register onRegisterSuccess={handleRegisterSuccess} />
      )}
      {currentView === "disclaimer" && (
        <Disclaimer 
     //   dvId={deviceId} // Pass the deviceId as a prop
          usernm={username}  // Pass the username as a prop to Disclaimer
          onDisclaimerSuccess={handleDisclaimerSuccess} 
        />
      )}
      {currentView === "excelTable" && (
        <ExcelTable usernm={username} payDate={paymentDate} emailId={email} dvId={deviceId} uId={userId} regDate={registrationDate} /> // Pass the deviceId as a prop/> // Pass the username as a prop to ExcelTable
      )}
      {currentView === "resetPassword" && (
        <ResetPassword onResetPasswordSuccess={handleResetPasswordSuccess} />
      )}
    </div>
  );
};

export default App;
